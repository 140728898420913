@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: 'Fira Code', monospace;
}

.background {
  background-color: #efefef;
}

.main {
  width: 85%;
  height: 100%;
  margin: auto;
  background-color: white;
  overflow: scroll;
}

.headerBar {
  display: flex;
  align-items: center;
  --header-width: 100%;
  --header-height: 10vh;
  height: var(--header-height);
  width: var(--header-width);
  background-color: white;
  border: 1px solid black;
  border-radius: 0px 0px 30px 30px;
  box-sizing: border-box;
}

.headerFont {
  font-family: 'Fira Code', monospace;
  font-size: calc(var(--header-height) * 0.8);
  text-align: left;
  margin-left: 7%;
}

@media only screen and (max-width: 1100px) {
  .headerBar {
    justify-content: center;
    --header-height: 6vh;
    height: var(--header-height);
  }

  .headerFont {
    font-family: 'Fira Code', monospace;
    font-size: calc(var(--header-height) * 0.8);
    text-align: center;
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  .headerBar {
    justify-content: center;
    --header-height: 8vh;
    height: var(--header-height);
  }

  .headerFont {
    font-family: 'Fira Code', monospace;
    font-size: 6vh;
    text-align: center;
    margin: 0;
  }
}

.makePost {
  display: block;
  width: 90%;
  height: 30vh;
  border: 2px #e5e5e5 solid;
  border-top: 0px;
  margin: auto;
  padding-bottom: 1%;
  overflow: auto;
  border-radius: 0 0 10px 10px;
}

.makePostHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1;
  align-content: space-between;
  justify-content: space-between;
  grid-row-gap: 1em;
  row-gap: 1em;
  padding: 0.5% 2%;
  background-color: #e5e5e5;
}

.makePostHeaderButton {
  background-color: #1da1f2;
  border: 2px solid #1da1f2;
  color: white;
  border-radius: 20px;
  height: 100%;
  width: 7em;
  font-size: 1.5em;
}

.makePostHeaderButton:hover {
  background-color: #136ba1;
  border: 2px solid #136ba1;
}

.makePostHeaderButton:active {
  background-color: #1da1f2;
  border: 2px solid #1da1f2;
}

.makePostHeaderTitle {
  border: none;
  outline: none;
  background-color: #e5e5e5;
  height: 1.5em;
  outline: none;
  resize: none;
}

.makePostLable {
  font-size: 1.5em;
}

.makePostHeaderContent {
  border: none;
  outline: none;
  width: 100%;
  height: 20vh;
  resize: none;
}

.makePostMain {
  display: flex;
  padding: 0.5% 2% 2% 2%;
  overflow: hidden;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.posts {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.post {
  display: block;
  width: 90%;
  margin-top: 4%;
  height: auto;
  border: 2px #e5e5e5 solid;
  border-radius: 10px 10px 10px 10px;
}

.postFont {
  font-size: 1.5em;
  color: gray;
}
.postMainFont {
  font-size: 1.5em;
  color: black;
}

.postHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1;
  align-content: space-between;
  justify-content: space-between;
  grid-row-gap: 1em;
  row-gap: 1em;
  padding: 0.5% 2%;
  background-color: #e5e5e5;
}

.postMain {
  padding: 1% 2%;
}

